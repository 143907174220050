import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  // 登录页
  {
    path: "/",
    name: "home",
    component: () => import('@/views/Login.vue')
  },
  //搜索页
  {
    path: '/SearchEngines',
    name: 'SearchEngines',
    component: () => import('@/views/SearchEngines.vue')
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/index.vue'),
    children: [
      {
        path:"/index", //智能推荐
        component: () => import('@/views/recommend/Recommend.vue')
      },
      {
        path:"SameScore", //同分去向
        component: () => import('@/views/sameScore/SameScore.vue')
      },
      {
        path:"SameSchool", //同校去向
        component: () => import('@/views/sameSchool/SameSchool.vue')
      },
      {
        path:"Hot", //热榜
        component: () => import('@/views/hot/Hot.vue')
      },
      {
        path:"PreviousYear", //往年
        component: () => import('@/views/previousYear/PreviousYear.vue')
      },
      {
        path:"PreviousList", //往年 列表
        component: () => import('@/views/previousYear/PreviousList.vue')
      },
      {
        path:"CheckData", //查数据
        component: () => import('@/views/checkData/CheckData.vue')
      },
      {
        path:"schoolSearch", //学校
        component: () => import('@/views/checkData/schoolSearch.vue')
      },
      {
        path:"Prediction", //预测
        component: () => import('@/views/prediction/Prediction.vue')
      },
      {
        path:"MyAdjust", //我的调剂
        component: () => import('@/views/myAdjust/MyAdjust.vue')
      },
      {
        path:"Estimate", //估分排名
        component: () => import('@/views/estimate/Estimate.vue')
      },
      {
        path:"detail", //详情
        component: () => import('@/views/detail/Detail.vue')
      },
      {
        path:"chooseDetail", //择校详情
        component: () => import('@/views/chooseDetail/index.vue')
      },

      {
        path:"subscribe", //订阅最新调剂
        component: () => import('@/views/latestadjustment/subscribe.vue')
      },
      {
        path:"latestlist", //最新调剂
        component: () => import('@/views/latestadjustment/latestlist.vue')
      },
      {
        path:"latesdetail", //最新调剂 详情
        component: () => import('@/views/latestadjustment/latesdetail.vue')
      },
      {
        path:"subsclsit", //最新 订阅列表
        component: () => import('@/views/latestadjustment/subsclsit.vue')
      },
 

    ]
  }
];

const router = new VueRouter({
  routes,
});



// 路由拦截器
router.beforeEach((to, from, next) => {
	if (to.matched.length != 0) {
		if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
			if (localStorage.getItem("xcx_pc_token")) { // 通过vuex state获取当前的是否存在
				next();
			} else {
				next({
					path: '/',
					query: {
						redirect: to.fullPath
					} // 将跳转的路由path作为参数，登录成功后跳转到该路由
				})
			}
		} else {
			if (localStorage.getItem("xcx_pc_token")) { // 判断是否登录
				if (to.path != "/" && to.path != "/") { //判断是否要跳到登录界面
					next();
				} else {
					/**
					 * 防刷新，如果登录，修改路由跳转到登录页面，修改路由为登录后的首页 
					 */
					next({
						path: '/SearchEngines'
					})
				}
			} else {
				next();
			}
		}
	} else {
		next({
			path: '/',
			query: {
				redirect: to.fullPath
			} // 将跳转的路由path作为参数，登录成功后跳转到该路由
		})
	}
})


export default router;
